import { create } from 'zustand';


//Zustand global State
const useInstStore = create((set) => ({

    //states
    inst: 0,
    keyRoot: 0,
    scaleType: 1,
    isMajor: true,
    isSharp: true,
    menu1: false,
    menu2: false,
    menu3: false,
    isFrets: 0,
    soundL: 0,
    
  
  // update states
  setInst: (value) => set({inst:value}),
  setKeyRoot: (value) => set({keyRoot: value}),
  setScaleType: (value) => set ({scaleType: value}),
  setIsMajor: (value) => set({isMajor: value}),
  setIsSharp: (value) => set({isSharp: value}),
  setMenu1: (value) => set({menu1: value}),
  setMenu2: (value) => set({menu2: value}),
  setMenu3: (value) => set({menu3: value}),
  setIsFrets: (value) => set({isFrets: value}),
  setSoundL: (value) => set({soundL: value})
  }))


  export default useInstStore;