export {spotIndex, VCspotIndex, noteSW, noteFW, noteSK, noteFK, 
  noteSG, noteFG, scales, sNoteNames, fNoteNames, 
  uiBtn, handPat, fretNames, v4Audio, instImg}; 

const sNoteNames = ["C", "C♯", "D", "D♯", "E", "F", "F♯", "G", "G♯", "A", "A♯", "B"]
const fNoteNames = ["C", "D♭", "D", "E♭", "E", "F", "G♭", "G", "A♭", "A", "B♭", "B"]

const instImg = [
    {tag: "v4_bg", name: "Fiddle Fingerboard", imgKey: "../public/images/v4_bg.png"},
    {tag: "v5_bg", name: "Five Strang Electric Fingerboard", imgKey: "../public/images/v5_bg.png"},
    {tag: "va_bg", name: "Viola Fingerboard", imgKey: "../public/images/va_bg.png"},
    {tag: "vc_bg", name: "Cello Fingerboard", imgKey: "../public/images/vc_bg.png"},
]

const VCspotIndex = [
  {tag: "c_op", name: "C2", audKey: "../audio/combo/2c.mp3 "},
  {tag: "c_1L", name: "C♯2", audKey: "../audio/combo/2c_s.mp3 "},
  {tag: "c_1H", name: "D2", audKey: "../audio/combo/2d.mp3 "},
  {tag: "c_2L", name: "D♯2", audKey: "../audio/combo/2d_s.mp3 "},
  {tag: "c_2H", name: "E2", audKey: "../audio/combo/2e.mp3 "},
  {tag: "c_3L", name: "F2", audKey: "../audio/combo/2f.mp3 "},
  {tag: "c_3H", name: "F♯2", audKey: "../audio/combo/2f_s.mp3 "},
  {tag: "c_4", name: "G2", audKey: "../audio/combo/2g.mp3 "},
  {tag: "c_5L", name: "G♯2", audKey: "../audio/combo/2g_s.mp3 "},
  {tag: "c_5H", name: "A2", audKey: "../audio/combo/2a.mp3 "},
  {tag: "c_6L", name: "A♯2", audKey: "../audio/combo/2a_s.mp3 "},
  {tag: "c_6H", name: "B2", audKey: "../audio/combo/2b.mp3 "},
  {tag: "c_7L", name: "C3", audKey: "../audio/combo/3c.mp3 "},
  {tag: "c_7H", name: "C♯3", audKey: "../audio/combo/3c_s.mp3 "},
  {tag: "c_8L", name: "D3", audKey: "../audio/combo/3d.mp3 "},
  {tag: "c_8H", name: "D♯3", audKey: "../audio/combo/3d_s.mp3 "},
  {tag: "c_9L", name: "E3", audKey: "../audio/combo/3e.mp3 "},
  {tag: "c_9H", name: "F3", audKey: "../audio/combo/3f.mp3 "},
  {tag: "", name: "F♯3", audKey: "../audio/combo/3f_s.mp3 "},
  {tag: "g_op", name: "G2", audKey: "../audio/combo/2g.mp3 "},
  {tag: "g_1L", name: "G♯2", audKey: "../audio/combo/2g_s.mp3 "},
  {tag: "g_1H", name: "A2", audKey: "../audio/combo/2a.mp3 "},
  {tag: "g_2L", name: "A♯2", audKey: "../audio/combo/2a_s.mp3 "},
  {tag: "g_2H", name: "B2", audKey: "../audio/combo/2b.mp3 "},
  {tag: "g_3L", name: "C3", audKey: "../audio/combo/3c.mp3 "},
  {tag: "g_3H", name: "C♯3", audKey: "../audio/combo/3c_s.mp3 "},
  {tag: "g_4", name: "D3", audKey: "../audio/combo/3d.mp3 "},
  {tag: "g_5L", name: "D♯3", audKey: "../audio/combo/3d_s.mp3 "},
  {tag: "g_5H", name: "E3", audKey: "../audio/combo/3e.mp3 "},
  {tag: "g_6L", name: "F3", audKey: "../audio/combo/3f.mp3 "},
  {tag: "g_6H", name: "F♯3", audKey: "../audio/combo/3f_s.mp3 "},
  {tag: "g_7L", name: "G3", audKey: "../audio/combo/3g.mp3 "},
  {tag: "g_7H", name: "G♯3", audKey: "../audio/combo/3g_s.mp3 "},
  {tag: "g_8L", name: "A3", audKey: "../audio/combo/3a.mp3 "},
  {tag: "g_8H", name: "A♯3", audKey: "../audio/combo/3a_s.mp3 "},
  {tag: "g_9L", name: "B3", audKey: "../audio/combo/3b.mp3 "},
  {tag: "g_9H", name: "C4", audKey: "../audio/combo/4c.mp3 "},
  {tag: "", name: "C♯4", audKey: "../audio/combo/4c_s.mp3 "},
  {tag: "d_op", name: "D3", audKey: "../audio/combo/3d.mp3 "},
  {tag: "d_1L", name: "D♯3", audKey: "../audio/combo/3d_s.mp3 "},
  {tag: "d_1H", name: "E3", audKey: "../audio/combo/3e.mp3 "},
  {tag: "d_2L", name: "F3", audKey: "../audio/combo/3f.mp3 "},
  {tag: "d_2H", name: "F♯3", audKey: "../audio/combo/3f_s.mp3 "},
  {tag: "d_3L", name: "G3", audKey: "../audio/combo/3g.mp3 "},
  {tag: "d_3H", name: "G♯3", audKey: "../audio/combo/3g_s.mp3 "},
  {tag: "d_4", name: "A3", audKey: "../audio/combo/3a.mp3 "},
  {tag: "d_5L", name: "A♯3", audKey: "../audio/combo/3a_s.mp3 "},
  {tag: "d_5H", name: "B3", audKey: "../audio/combo/3b.mp3 "},
  {tag: "d_6L", name: "C4", audKey: "../audio/combo/4c.mp3 "},
  {tag: "d_6H", name: "C♯4", audKey: "../audio/combo/4c_s.mp3 "},
  {tag: "d_7L", name: "D4", audKey: "../audio/combo/4d.mp3 "},
  {tag: "d_7H", name: "D♯4", audKey: "../audio/combo/4d_s.mp3 "},
  {tag: "d_8L", name: "E4", audKey: "../audio/combo/4e.mp3 "},
  {tag: "d_8H", name: "F4", audKey: "../audio/combo/4f.mp3 "},
  {tag: "d_9L", name: "F♯4", audKey: "../audio/combo/4f_s.mp3 "},
  {tag: "d_9H", name: "G4", audKey: "../audio/combo/4g.mp3 "},
  {tag: "", name: "G♯4", audKey: "../audio/combo/4g_s.mp3 "},
  {tag: "a_op", name: "A3", audKey: "../audio/combo/3a.mp3 "},
  {tag: "a_1L", name: "A♯3", audKey: "../audio/combo/3a_s.mp3 "},
  {tag: "a_1H", name: "B3", audKey: "../audio/combo/3b.mp3 "},
  {tag: "a_2L", name: "C4", audKey: "../audio/combo/4c.mp3 "},
  {tag: "a_2H", name: "C♯4", audKey: "../audio/combo/4c_s.mp3 "},
  {tag: "a_3L", name: "D4", audKey: "../audio/combo/4d.mp3 "},
  {tag: "a_3H", name: "D♯4", audKey: "../audio/combo/4d_s.mp3 "},
  {tag: "a_4", name: "E4", audKey: "../audio/combo/4e.mp3 "},
  {tag: "a_5L", name: "F4", audKey: "../audio/combo/4f.mp3 "},
  {tag: "a_5H", name: "F♯4", audKey: "../audio/combo/4f_s.mp3 "},
  {tag: "a_6L", name: "G4", audKey: "../audio/combo/4g.mp3 "},
  {tag: "a_6H", name: "G♯4", audKey: "../audio/combo/4g_s.mp3 "},
  {tag: "a_7L", name: "A4", audKey: "../audio/combo/4a.mp3 "},
  {tag: "a_7H", name: "A♯4", audKey: "../audio/combo/4a_s.mp3 "},
  {tag: "a_8L", name: "B4", audKey: "../audio/combo/4b.mp3 "},
  {tag: "a_8H", name: "C5", audKey: "../audio/combo/5c.mp3 "},
  {tag: "a_9L", name: "C♯5", audKey: "../audio/combo/5c_s.mp3 "},
  {tag: "a_9H", name: "D5", audKey: "../audio/combo/5d.mp3 "},
  {tag: "", name: "D♯5", audKey: "../audio/combo/5d_s.mp3 "},
  {tag: "e_op", name: "E4", audKey: "../audio/combo/4e.mp3 "},
  {tag: "e_1L", name: "F4", audKey: "../audio/combo/4f.mp3 "},
  {tag: "e_1H", name: "F♯4", audKey: "../audio/combo/4f_s.mp3 "},
  {tag: "e_2L", name: "G4", audKey: "../audio/combo/4g.mp3 "},
  {tag: "e_2H", name: "G♯4", audKey: "../audio/combo/4g_s.mp3 "},
  {tag: "e_3L", name: "A4", audKey: "../audio/combo/4a.mp3 "},
  {tag: "e_3H", name: "A♯4", audKey: "../audio/combo/4a_s.mp3 "},
  {tag: "e_4", name: "B4", audKey: "../audio/combo/4b.mp3 "},
  {tag: "e_5L", name: "C5", audKey: "../audio/combo/5c.mp3 "},
  {tag: "e_5H", name: "C♯5", audKey: "../audio/combo/5c.mp3 "},
  {tag: "e_6L", name: "D5", audKey: "../audio/combo/5d.mp3 "},
  {tag: "e_6H", name: "D♯5", audKey: "../audio/combo/5d.mp3 "},
  {tag: "e_7L", name: "E5", audKey: "../audio/combo/5e.mp3 "},
  {tag: "e_7H", name: "F5", audKey: "../audio/combo/5f.mp3 "},
  {tag: "e_8L", name: "F♯5", audKey: "../audio/combo/5f.mp3 "},
  {tag: "e_8H", name: "G5", audKey: "../audio/combo/5g.mp3 "},
  {tag: "e_9L", name: "G♯5", audKey: "../audio/combo/5g.mp3 "},
  {tag: "e_9H", name: "A5", audKey: "../audio/combo/5a.mp3 "},
]

const spotIndex = [
  {tag: "c_op", name: "C3", audKey: "../audio/combo/3c.mp3"},
  {tag: "c_1L", name: "C♯3", audKey: "../audio/combo/3c_s.mp3"},
  {tag: "c_1H", name: "D3", audKey: "../audio/combo/3d.mp3"},
  {tag: "c_2L", name: "D♯3", audKey: "../audio/combo/3d_s.mp3"},
  {tag: "c_2H", name: "E3", audKey: "../audio/combo/3e.mp3"},
  {tag: "c_3L", name: "F3", audKey: "../audio/combo/3f.mp3"},
  {tag: "c_3H", name: "F♯3", audKey: "../audio/combo/3f_s.mp3"},
  {tag: "c_4", name: "G3", audKey: "../audio/combo/3g.mp3"},
  {tag: "c_5L", name: "G♯3", audKey: "../audio/combo/3g_s.mp3"},
  {tag: "c_5H", name: "A3", audKey: "../audio/combo/3a.mp3"},
  {tag: "c_6L", name: "A♯3", audKey: "../audio/combo/3a_s.mp3"},
  {tag: "c_6H", name: "B3", audKey: "../audio/combo/3b.mp3"},
  {tag: "c_7L", name: "C4", audKey: "../audio/combo/4c_s.mp3"},
  {tag: "c_7H", name: "C♯4", audKey: "../audio/combo/3c_s.mp3"},
  {tag: "c_8L", name: "D4", audKey: "../audio/combo/3d.mp3"},
  {tag: "c_8H", name: "D♯4", audKey: "../audio/combo/3d_s.mp3"},
  {tag: "c_9L", name: "E4", audKey: "../audio/combo/3e.mp3"},
  {tag: "c_9H", name: "F4", audKey: "../audio/combo/3f.mp3"},
  {tag: "", name: "F♯4", audKey: "../audio/combo/3f_s.mp3"},
  {tag: "g_op", name: "G3", audKey: "../audio/combo/3g.mp3"},
  {tag: "g_1L", name: "G♯3", audKey: "../audio/combo/3g_s.mp3"},
  {tag: "g_1H", name: "A3", audKey: "../audio/combo/3a.mp3"},
  {tag: "g_2L", name: "A♯3", audKey: "../audio/combo/3a_s.mp3"},
  {tag: "g_2H", name: "B3", audKey: "../audio/combo/3b.mp3"},
  {tag: "g_3L", name: "C4", audKey: "../audio/combo/4c.mp3"},
  {tag: "g_3H", name: "C♯4", audKey: "../audio/combo/4c_s.mp3"},
  {tag: "g_4", name: "D4", audKey: "../audio/combo/4d.mp3"},
  {tag: "g_5L", name: "D♯4", audKey: "../audio/combo/4d_s.mp3"},
  {tag: "g_5H", name: "E4", audKey: "../audio/combo/4e.mp3"},
  {tag: "g_6L", name: "F4", audKey: "../audio/combo/4f.mp3"},
  {tag: "g_6H", name: "F♯4", audKey: "../audio/combo/4f_s.mp3"},
  {tag: "g_7L", name: "G4", audKey: "../audio/combo/3g.mp3"},
  {tag: "g_7H", name: "G♯4", audKey: "../audio/combo/3g_s.mp3"},
  {tag: "g_8L", name: "A4", audKey: "../audio/combo/3a.mp3"},
  {tag: "g_8H", name: "A♯4", audKey: "../audio/combo/3a_s.mp3"},
  {tag: "g_9L", name: "B4", audKey: "../audio/combo/3b.mp3"},
  {tag: "g_9H", name: "C5", audKey: "../audio/combo/4c.mp3"},
  {tag: "", name: "C♯5", audKey: "../audio/combo/4c_s.mp3"},
  {tag: "d_op", name: "D4", audKey: "../audio/combo/4d.mp3"},
  {tag: "d_1L", name: "D♯4", audKey: "../audio/combo/4d_s.mp3"},
  {tag: "d_1H", name: "E4", audKey: "../audio/combo/4e.mp3"},
  {tag: "d_2L", name: "F4", audKey: "../audio/combo/4f.mp3"},
  {tag: "d_2H", name: "F♯4", audKey: "../audio/combo/4f_s.mp3"},
  {tag: "d_3L", name: "G4", audKey: "../audio/combo/4g.mp3"},
  {tag: "d_3H", name: "G♯4", audKey: "../audio/combo/4g_s.mp3"},
  {tag: "d_4", name: "A4", audKey: "../audio/combo/4a.mp3"},
  {tag: "d_5L", name: "A♯4", audKey: "../audio/combo/4a_s.mp3"},
  {tag: "d_5H", name: "B4", audKey: "../audio/combo/4b.mp3"},
  {tag: "d_6L", name: "C5", audKey: "../audio/combo/5c.mp3"},
  {tag: "d_6H", name: "C♯5", audKey: "../audio/combo/5c_s.mp3"},
  {tag: "d_7L", name: "D5", audKey: "../audio/combo/4d.mp3"},
  {tag: "d_7H", name: "D♯5", audKey: "../audio/combo/4d_s.mp3"},
  {tag: "d_8L", name: "E5", audKey: "../audio/combo/4e.mp3"},
  {tag: "d_8H", name: "F5", audKey: "../audio/combo/4f.mp3"},
  {tag: "d_9L", name: "F♯5", audKey: "../audio/combo/4f_s.mp3"},
  {tag: "d_9H", name: "G5", audKey: "../audio/combo/4g.mp3"},
  {tag: "", name: "G♯5", audKey: "../audio/combo/4g_s.mp3"},
  {tag: "a_op", name: "A4", audKey: "../audio/combo/4a.mp3"},
  {tag: "a_1L", name: "A♯4", audKey: "../audio/combo/4a_s.mp3"},
  {tag: "a_1H", name: "B4", audKey: "../audio/combo/4b.mp3"},
  {tag: "a_2L", name: "C5", audKey: "../audio/combo/5c.mp3"},
  {tag: "a_2H", name: "C♯5", audKey: "../audio/combo/5c_s.mp3"},
  {tag: "a_3L", name: "D5", audKey: "../audio/combo/5d.mp3"},
  {tag: "a_3H", name: "D♯5", audKey: "../audio/combo/5d_s.mp3"},
  {tag: "a_4", name: "E5", audKey: "../audio/combo/5e.mp3"},
  {tag: "a_5L", name: "F5", audKey: "../audio/combo/5f.mp3"},
  {tag: "a_5H", name: "F♯5", audKey: "../audio/combo/5f_s.mp3"},
  {tag: "a_6L", name: "G5", audKey: "../audio/combo/5g.mp3"},
  {tag: "a_6H", name: "G♯5", audKey: "../audio/combo/5g_s.mp3"},
  {tag: "a_7L", name: "A5", audKey: "../audio/combo/5a.mp3"},
  {tag: "a_7H", name: "A♯5", audKey: "../audio/combo/5a_s.mp3"},
  {tag: "a_8L", name: "B5", audKey: "../audio/combo/6b.mp3"},
  {tag: "a_8H", name: "C6", audKey: "../audio/combo/6c.mp3"},
  {tag: "a_9L", name: "C♯6", audKey: "../audio/combo/6c_s.mp3"},
  {tag: "a_9H", name: "D6", audKey: "../audio/combo/6d.mp3"},
  {tag: "", name: "D♯6", audKey: "../audio/combo/6d_s.mp3"},
  {tag: "e_op", name: "E5", audKey: "../audio/combo/5e.mp3"},
  {tag: "e_1L", name: "F5", audKey: "../audio/combo/5f.mp3"},
  {tag: "e_1H", name: "F♯5", audKey: "../audio/combo/5f_s.mp3"},
  {tag: "e_2L", name: "G5", audKey: "../audio/combo/5g.mp3"},
  {tag: "e_2H", name: "G♯5", audKey: "../audio/combo/5g_s.mp3"},
  {tag: "e_3L", name: "A5", audKey: "../audio/combo/5a.mp3"},
  {tag: "e_3H", name: "A♯5", audKey: "../audio/combo/5a_s.mp3"},
  {tag: "e_4", name: "B5", audKey: "../audio/combo/5b.mp3"},
  {tag: "e_5L", name: "C6", audKey: "../audio/combo/6c.mp3"},
  {tag: "e_5H", name: "C♯6", audKey: "../audio/combo/6c.mp3"},
  {tag: "e_6L", name: "D6", audKey: "../audio/combo/6d.mp3"},
  {tag: "e_6H", name: "D♯6", audKey: "../audio/combo/6d.mp3"},
  {tag: "e_7L", name: "E6", audKey: "../audio/combo/6e.mp3"},
  {tag: "e_7H", name: "F6", audKey: "../audio/combo/6f.mp3"},
  {tag: "e_8L", name: "F♯6", audKey: "../audio/combo/6f.mp3"},
  {tag: "e_8H", name: "G6", audKey: "../audio/combo/6g.mp3"},
  {tag: "e_9L", name: "G♯6", audKey: "../audio/combo/6g.mp3"},
  {tag: "e_9H", name: "A6", audKey: "../audio/combo/6a.mp3"}
]


const uiBtn = [
  {id:1, imgKey: '../images/isSharp_fw.svg'},
  {id:2, imgKey: '../images/isSharp_sw.svg'},
  {id:3, imgKey: '../images/hand_0.svg'},
  {id:4, imgKey: '../images/hand_1.svg'},
  {id:5, imgKey: '../images/hand_2.svg'},
  {id:6, imgKey: '../images/hand_3.svg'},
  {id:7, imgKey: '../images/hand_4.svg'},
  {id:8, imgKey: '../images/sett_h.svg'},
  {id:9, imgKey: '../images/sett_x.svg'},
  {id:10, imgKey: '../images/v4_btn.png'},
  {id:11, imgKey: '../images/v5_btn.png'},
  {id:12, imgKey: '../images/va_btn.png'},
  {id:13, imgKey: '../images/vc_btn.png'},
  {id:14, imgKey: '../images/next_btn.svg'},
  {id:15, imgKey: '../images/play_btn.svg'},
  {id:16, imgKey: '../images/ChordRef_mj.svg'},
  {id:17, imgKey: '../images/ChordRef_mn.svg'},
  {id:18, imgKey: '../images/sound_w.svg'},
  {id:19, imgKey: '../images/sound_h.svg'},
  {id:20, imgKey: '../images/sound_q.svg'},
  {id:21, imgKey: '../images/sound_e.svg'},
]

const noteSW = [
  {id:1, imgKey: '../images/c_w.svg'},
  {id:2, imgKey: '../images/c_s_w.svg'},
  {id:3, imgKey: '../images/d_w.svg'},
  {id:4, imgKey: '../images/d_s_w.svg'},
  {id:5, imgKey: '../images/e_w.svg'},
  {id:6, imgKey: '../images/f_w.svg'},
  {id:7, imgKey: '../images/f_s_w.svg'},
  {id:8, imgKey: '../images/g_w.svg'},
  {id:9, imgKey: '../images/g_s_w.svg'},
  {id:10, imgKey: '../images/a_w.svg'},
  {id:11, imgKey: '../images/a_s_w.svg'},
  {id:12, imgKey: '../images/b_w.svg'}
  ]

const noteFW = [
  {id: 1, imgKey: '../images/c_w.svg'},
  {id:2, imgKey: '../images/d_f_w.svg'},
  {id:3, imgKey: '../images/d_w.svg'},
  {id:4, imgKey: '../images/e_f_w.svg'},
  {id:5, imgKey: '../images/e_w.svg'},
  {id:6, imgKey: '../images/f_w.svg'},
  {id:7, imgKey: '../images/g_f_w.svg'},
  {id:8, imgKey: '../images/g_w.svg'},
  {id:9, imgKey: '../images/a_f_w.svg'},
  {id:10, imgKey: '../images/a_w.svg'},
  {id:11, imgKey: '../images/b_f_w.svg'},
  {id:12, imgKey: '../images/b_w.svg'}
  ]

const noteSK = [
  {id:1, imgKey: '../images/c_k.svg'},
  {id:2, imgKey: '../images/c_s_k.svg'},
  {id:3, imgKey: '../images/d_k.svg'},
  {id:4, imgKey: '../images/d_s_k.svg'},
  {id:5, imgKey: '../images/e_k.svg'},
  {id:6, imgKey: '../images/f_k.svg'},
  {id:7, imgKey: '../images/f_s_k.svg'},
  {id:8, imgKey: '../images/g_k.svg'},
  {id:9, imgKey: '../images/g_s_k.svg'},
  {id:10, imgKey: '../images/a_k.svg'},
  {id:11, imgKey: '../images/a_s_k.svg'},
  {id:12, imgKey: '../images/b_k.svg'}
  ]

const noteFK = [
  {id:1, imgKey: '../images/c_k.svg'},
  {id:2, imgKey: '../images/d_f_k.svg'},
  {id:3, imgKey: '../images/d_k.svg'},
  {id:4, imgKey: '../images/e_f_k.svg'},
  {id:5, imgKey: '../images/e_k.svg'},
  {id:6, imgKey: '../images/f_k.svg'},
  {id:7, imgKey: '../images/g_f_k.svg'},
  {id:8, imgKey: '../images/g_k.svg'},
  {id:9, imgKey: '../images/a_f_k.svg'},
  {id:10, imgKey: '../images/a_k.svg'},
  {id:11, imgKey: '../images/b_f_k.svg'},
  {id:12, imgKey: '../images/b_k.svg'}
  ]

const noteSG = [
  {id:1, imgKey: '../images/c_g.svg'},
  {id:2, imgKey: '../images/c_s_g.svg'},
  {id:3, imgKey: '../images/d_g.svg'},
  {id:4, imgKey: '../images/d_s_g.svg'},
  {id:5, imgKey: '../images/e_g.svg'},
  {id:6, imgKey: '../images/f_g.svg'},
  {id:7, imgKey: '../images/f_s_g.svg'},
  {id:8, imgKey: '../images/g_g.svg'},
  {id:9, imgKey: '../images/g_s_g.svg'},
  {id:10, imgKey: '../images/a_g.svg'},
  {id:11, imgKey: '../images/a_s_g.svg'},
  {id:12, imgKey: '../images/b_g.svg'}
  ]

const noteFG = [
  {id:1, imgKey: '../images/c_g.svg'},
  {id:2, imgKey: '../images/d_f_g.svg'},
  {id:3, imgKey: '../images/d_g.svg'},
  {id:4, imgKey: '../images/e_f_g.svg'},
  {id:5, imgKey: '../images/e_g.svg'},
  {id:6, imgKey: '../images/f_g.svg'},
  {id:7, imgKey: '../images/g_f_g.svg'},
  {id:8, imgKey: '../images/g_g.svg'},
  {id:9, imgKey: '../images/a_f_g.svg'},
  {id:10, imgKey: '../images/a_g.svg'},
  {id:11, imgKey: '../images/b_f_g.svg'},
  {id:12, imgKey: '../images/b_g.svg'}
  ]

const scales = [
  {tag: "Ch",  name: "Chromatic", pattern: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, ]}, 
  {tag: "Mj",  name: "Major Scale", pattern: [1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, ]},
  {tag: "Mn",  name: "Minor Scale", pattern: [1, 0, 1, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 1, 0, 1, 0,]},
  {tag: "MnH", name: "Minor Harmonic Scale", pattern: [1, 0, 1, 1, 0, 1, 0, 1, 1, 0, 0, 1, 1, 0, 1, 1, 0, 1, 0, 1, 1, 0, 0, 1,]},
  {tag: "MnM", name: "Minor Melodic Scale", pattern: [1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0, 1,]},
  {tag: "PentMj", name: "Major Pentatonic Scale", pattern: [1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 0,]},
  {tag: "PentMn", name: "Minor Pentatonic Scale", pattern: [1, 0, 1, 1, 0, 0, 0, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 0, 0, 1, 0, 1, 0, 0,]},
  {tag: "TrMj", name: "Major Triad Scale", pattern: [1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, ]},
  {tag: "TrMn", name: "Minor Triad Scale", pattern: [1, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, ]},
  {tag: "dorMode",  name: "Dorian Mode", pattern: [1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 1, 0,]},
  {tag: "phrMode",  name: "Phrygian Mode", pattern: [1, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0,]},
  {tag: "lydMode",  name: "Lydian Mode", pattern: [1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, ]},
  {tag: "mixMode",  name: "Mixolydian Mode", pattern: [1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 1, 0,]},
  {tag: "aeoMode",  name: "Aeolian Mode", pattern: [1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 1, 0,]},
  {tag: "locMode",  name: "Locrian Mode", pattern: [1, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0,]},

]

const handPat = [
  {tag: "hand0", name: "Hand0", pattern: [0, 0, 0, 0, 0, 0, 0, 0]},
  {tag: "hand1", name: "Hand1", pattern: [0, 1, 2, 0, 1, 0, 2, 0]},
  {tag: "hand2", name: "Hand2", pattern: [0, 1, 0, 2, 1, 0, 2, 0]},
  {tag: "hand3", name: "Hand3", pattern: [0, 1, 0, 2, 0, 1, 2, 0]},
  {tag: "hand4", name: "Hand4", pattern: [0, 1, 0, 2, 0, 1, 0, 2]}
  ]

const fretNames = [
  //needed? 
  {tag: "_op", name: "Open String"},
  {tag: "_1L", name: "First - Low"},
  {tag: "_1H", name: "First - High"},
  {tag: "_2L", name: "Second - Low"},
  {tag: "_2H", name: "Second - High"},
  {tag: "_3L", name: "Third - Low"},
  {tag: "_3H", name: "Third - High"},
  {tag: "_4", name: "Fourth"},
  {tag: "_5L", name: "Fifth - Low"},
  {tag: "_5H", name: "Fifth - High"},
  {tag: "_6L", name: "Sixth - Low"},
  {tag: "_6H", name: "Sixth - High"},
  {tag: "_7L", name: "Seventh - Low"},
  {tag: "_7H", name: "Seventh - High"},
  {tag: "_8L", name: "Eighth - Low"},
  {tag: "_8H", name: "Eighth - High"},
  {tag: "_9L", name: "Ninth - Low"},
  {tag: "_9H", name: "Ninth - High"}
  ]

const v4Audio = [  
  {audKey: '../audio/combo/v4/3a.mp3'},
  {audKey: '../audio/combo/v4/3a_s.mp3'},
  {audKey: '../audio/combo/v4/3b.mp3'},
  {audKey: '../audio/combo/v4/3g.mp3'},
  {audKey: '../audio/combo/v4/3g_s.mp3'},
  {audKey: '../audio/combo/v4/4a.mp3'},
  {audKey: '../audio/combo/v4/4a_s.mp3'},
  {audKey: '../audio/combo/v4/4b.mp3'},
  {audKey: '../audio/combo/v4/4c.mp3'},
  {audKey: '../audio/combo/v4/4c_s.mp3'},
  {audKey: '../audio/combo/v4/4d.mp3'},
  {audKey: '../audio/combo/v4/4d_s.mp3'},
  {audKey: '../audio/combo/v4/4e.mp3'},
  {audKey: '../audio/combo/v4/4f.mp3'},
  {audKey: '../audio/combo/v4/4f_s.mp3'},
  {audKey: '../audio/combo/v4/4g.mp3'},
  {audKey: '../audio/combo/v4/4g_s.mp3'},
  {audKey: '../audio/combo/v4/5a.mp3'},
  {audKey: '../audio/combo/v4/5a_s.mp3'},
  {audKey: '../audio/combo/v4/5b.mp3'},
  {audKey: '../audio/combo/v4/5c.mp3'},
  {audKey: '../audio/combo/v4/5c_s.mp3'},
  {audKey: '../audio/combo/v4/5d.mp3'},
  {audKey: '../audio/combo/v4/5d_s.mp3'},
  {audKey: '../audio/combo/v4/5e.mp3'},
  {audKey: '../audio/combo/v4/5f.mp3'},
  {audKey: '../audio/combo/v4/5f_s.mp3'},
  {audKey: '../audio/combo/v4/5g.mp3'},
  {audKey: '../audio/combo/v4/5g_s.mp3'},
  {audKey: '../audio/combo/v4/6a.mp3'},
  {audKey: '../audio/combo/v4/6a_s.mp3'},
  {audKey: '../audio/combo/v4/6c.mp3'},
  {audKey: '../audio/combo/v4/6c_s.mp3'},
  {audKey: '../audio/combo/v4/6d.mp3'},
  {audKey: '../audio/combo/v4/6d_s.mp3'},
  {audKey: '../audio/combo/v4/6e.mp3'},
  {audKey: '../audio/combo/v4/6f.mp3'},
  {audKey: '../audio/combo/v4/6f_s.mp3'},
  {audKey: '../audio/combo/v4/6g.mp3'},
  {audKey: '../audio/combo/v4/6g_s.mp3'}
]
  