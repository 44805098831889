import './charts.css';
import './circle.css';
import './settingsMenu.css';
import { spotIndex, VCspotIndex, noteSW, noteFW, noteSK, noteFK, 
  noteSG, noteFG, scales, sNoteNames, fNoteNames, 
  uiBtn, handPat, fretNames, v4Audio, instImg
   } from './markerLibrary.js'
import useInstStore from './useInstStore';
// import Circles from './circleMenu';
import { useState, useRef } from 'react';

function App() {
  
  const {
    menu1,
    menu2,
    menu3,
  } = useInstStore()
  
  let instruments = ["v4", "v5", "va", "vc"]
  if (menu1 || menu2 || menu3 ) {
    console.log("menu open")
    document.body.style.overflow = "hidden"
  } else {
    console.log("menu closed")
    document.body.style.overflow = "scroll"
  }
  return (
    <div>
      <NavBar />
      <BtnIsSharp />
      <BtnXMenu1 />

      <BtnChord />
      <BtnFrets />
      <BtnSettings />
      <ChordRef />
      <BtnSound />

      <Circles />
      <Settings />
       <div className="wrapper">
          <FngrbrdContainer />   
      </div>
    </div>
  )
}

function NavBar() {

  const {
    keyRoot,
    isMajor,
    isSharp,
    scaleType,
    menu1,
    menu3,
    setKeyRoot,
    setIsMajor,
    setScaleType,
    setMenu1,
    
  } = useInstStore()

  let offset = isMajor ? 9 : 3
  
  const scaleHandler= (e) => {
    const value = parseInt(e.target.value, 10 )
    setScaleType(value)
    switch(value){
        case 1:
        case 5:
        case 7:
        case 9:
        case 0:
          setIsMajor(true)
          break;
        case 2:
        case 3:
        case 4:
        case 6:
        case 8:
          setIsMajor(false)
          break;
    }
  }

  //changes Root and pattern101
  function seconaryKeyBtn () {
      setKeyRoot((keyRoot + offset) % 12)
    setIsMajor(!isMajor)

    switch(scaleType){
      case 1:
         setScaleType(2)
        break;
        case 2:
        case 3:
        case 4:
          setScaleType(1)
        break;
        case 5:
          setScaleType(6)
          break;
        case 6:
          setScaleType(5)
          break;
          case 7:
            setScaleType(8)
            break;
          case 8:
            setScaleType(7)
            break;
          default:
          setScaleType(1)
    }
   }

  return (
    <div className="titlenav">
    <span 
      id="chartTitle1" 
      className="keyName1" 
      onClick={() => setMenu1(!menu1)}> 
        {isSharp ? sNoteNames[keyRoot] : fNoteNames[keyRoot]} {isMajor? " Major" : " Minor"} 
    </span>
    <span 
      id="titlePipe"> | </span>
    <span 
      id="chartTitle2" 
      className="keyName2" 
      onClick={seconaryKeyBtn}>
        {isSharp ? sNoteNames[(keyRoot + offset) % 12] : fNoteNames[(keyRoot + offset) % 12]} {!isMajor? " Major" : " Minor"}
        </span><br />
    {!menu3 ? <ScaleSelect scaleHandler={scaleHandler} />: <span id="chartDesc">Chord Reference</span> }
    </div>
  )

}

function ScaleSelect({scaleHandler}) {

  const {scaleType} = useInstStore()

  return (
    <select id="chartDesc" value={scaleType} onChange={scaleHandler}>
      <option value={1}>{scales[1].name}</option>
      <option value={2}>{scales[2].name}</option>
      <option value={3}>{scales[3].name}</option>
      <option value={4}>{scales[4].name}</option>
      <option value={5}>{scales[5].name}</option>
      <option value={6}>{scales[6].name}</option>
      <option value={7}>{scales[7].name}</option>
      <option value={8}>{scales[8].name}</option>
      <option value={0}>{scales[0].name}</option>
      <option value={9}>{scales[9].name}</option>
      <option value={10}>{scales[10].name}</option>
      <option value={11}>{scales[11].name}</option>
      <option value={12}>{scales[12].name}</option>
      <option value={13}>{scales[13].name}</option>
      <option value={14}>{scales[14].name}</option>
    </select>
  )
}


function BtnXMenu1 () {
  
  const { menu1, setMenu1} = useInstStore();
  return(
    <>
      <img 
        id="xmenu1-btn" 
        className={menu1 ? "active" : ""} 
        src={uiBtn[8].imgKey} 
        onClick={() => setMenu1(false)} />
    </>  
  )
}

function BtnIsSharp () {
  
  const {isSharp, menu1, setIsSharp} = useInstStore();

  //for switching the appearance of the isSharp buttons
  let uiB
  if(isSharp) {uiB = 1} else {uiB = 0}

  return(
    <>
      <img 
        id="is-sharp-btn" 
        className={menu1 ? "active" : ""} 
        src={uiBtn[uiB].imgKey} 
        onClick={() => setIsSharp(!isSharp)} />
    </>  
  )
}

function BtnSettings () {
  
  const {menu2, setMenu2} = useInstStore();

  //for switching the appearance of the isSharp buttons
  let uiB
  if(menu2) {uiB = 8} else {uiB = 7}

  return(
    <>
      <img id="sett-btn" className={menu2 ? "active" : ""} src={uiBtn[uiB].imgKey} onClick={() => setMenu2(!menu2)} />
    </>  
  )
}

function BtnChord () {
 
  const {menu3, setMenu3, isMajor} = useInstStore();
 let uiB
  if(isMajor){uiB = 15} else {uiB = 16}

  //for switching the appearance of the Chord Reference button
  return(
    <>
      <img id="chord-btn" className={menu3 ? "active" : ""} src={menu3 ? uiBtn[8].imgKey : uiBtn[uiB].imgKey} onClick={() => setMenu3(!menu3)} />
    </>  
  )
}

function BtnFrets () {
  const {isFrets, setIsFrets} = useInstStore();

  //for switching the appearance of the isSharp buttons
  const uiB = uiBtn.slice(2)

  return(
    <>
      <img id="fret-btn" src={uiB[isFrets].imgKey} onClick={() => setIsFrets((isFrets + 1) % 5)} />
    </>  
  )
}

function BtnSound () {
  const {soundL, setSoundL, menu3} = useInstStore();

  const uiB = uiBtn.slice(17,21)

  return(
    <>
      <img 
      id="sound-btn" 
      className={menu3? "active": "" }
      src={uiB[soundL].imgKey} 
      onClick={() => setSoundL((soundL + 1) % 4)} />
    </>  
  )
}

function FngrbrdContainer () {
//if handPat: hide/show corresponding frets
    const {inst, isFrets, setIsFrets} = useInstStore(); 

    const [currPos, setCurrPos] = useState (0)
    let fretCSS = ["", "fret-highlight", "fret-lowlight"]

    function applyFrets(handPat, pos) {
      const fretNames = ["_op", "_1L", "_1H", "_2L", "_2H", "_3L", "_3H", "_4", "_5L", "_5H", "_6L", "_6H", "_7L", "_7H", "_8L", "_8H", "_9L", "_9H"]
    
       if(isFrets){return fretNames.map((n, i) => (
        <ChartFret key={i} fret={n} fretStyle={fretCSS[handPat[i-pos]]} fretID={i} isFrets={isFrets} currPos={currPos} setCurrPos={setCurrPos} />
      ))} else {
        return fretNames.map((n, i) => (
          <ChartFret key={i} fret={n} fretStyle={fretCSS[0]} fretID={i} currPos={currPos} setCurrPos={setCurrPos} />
          ))}
  }

  let bg
  switch(inst) {
    case 0:
    bg = "v4_bg"
    break;
    case 1:
      bg = "v5_bg"
    break;
    case 2:
      bg = "va_bg"
    break;
    case 3:
      bg = "vc_bg"
    break;
  }
  return(
  <div id="fngrbrd_container" className={bg}>
      <Spacer fret="space1"  fretStyle={fretCSS[0]} />
      {applyFrets(handPat[isFrets].pattern, currPos)}
      <Spacer fret="space1"  fretStyle={fretCSS[0]} />
  </div>
  )
}

function Spacer({fret}) {
  return (
      <div className="spacer strangs4" id={fret}></div>
  )
}

function ChartFret({fret, fretStyle, isFrets, currPos, setCurrPos, fretID}) {
  
  const {inst} = useInstStore()

  //if currInst, hide/show corresponding strangs
  let strangList = ["c", "g", "d", "a", "e"]
  let fretText

  function fretHandler() {
    if(isFrets) {setCurrPos(fretID-1)}
    }

  if(fretID > 0) {
    fretText = fretNames[fretID].name
  } else {
    fretText = ""
  }

  switch(inst) {
    //violin v4
    case  0:
    return (
        <div 
          className={"chart-frets " + fretStyle + " strangs4"} 
          id={fret} 
          onClick={fretHandler}>
            <Marker spot={strangList[1]+fret}  />
            <Marker spot={strangList[2]+fret}  />
            <Marker spot={strangList[3]+fret}  />
            <Marker spot={strangList[4]+fret}  />
            <span className="fretLabel">{fretText}</span>
        </div>
    )
    break;
     //Electric 5 strang v5
     case 1:
      return (
        <div 
          className={"chart-frets " + fretStyle + " strangs5"} 
          id={fret} 
          onClick={fretHandler}>
            <Marker spot={strangList[0]+fret}  />        
            <Marker spot={strangList[1]+fret}  />
            <Marker spot={strangList[2]+fret}  />
            <Marker spot={strangList[3]+fret}  />
            <Marker spot={strangList[4]+fret}  />
            <span className="fretLabel">{fretText}</span>
            </div>
    )
    break;
    //Viola va
    case 2:
    return (
        <div 
          className={"chart-frets " + fretStyle + " strangs4"} 
          id={fret} 
          onClick={fretHandler}>
            <Marker spot={strangList[0]+fret}  />
            <Marker spot={strangList[1]+fret}  />
            <Marker spot={strangList[2]+fret}  />
            <Marker spot={strangList[3]+fret}  />
            <span className="fretLabel">{fretText}</span>
        </div>
    )  
    break;
    //Cello vc
    case 3:
    return (
        <div 
          className={"chart-frets " + fretStyle + " strangs4"} 
          id={fret} 
          onClick={fretHandler}>
            <Marker spot={strangList[0]+fret}  />
            <Marker spot={strangList[1]+fret}  />
            <Marker spot={strangList[2]+fret}  />
            <Marker spot={strangList[3]+fret}  />
            <span className="fretLabel">{fretText}</span>
        </div>
    )
    break;
  }
}

function Marker({spot}) {

  const {inst, keyRoot, isSharp, scaleType, soundL} = useInstStore()

  // this is necessary to flip the direction, since the markers render horizontally but the notes increase up the string vertically
  const spotOptions = ["c_op",	"c_1L",	"c_1H",	"c_2L",	"c_2H",	"c_3L",	"c_3H",	"c_4",	"c_5L",	"c_5H",	"c_6L",	"c_6H",	"c_7L",	"c_7H",	"c_8L",	"c_8H",	"c_9L",	"c_9H", "", "g_op",	"g_1L",	"g_1H",	"g_2L",	"g_2H",	"g_3L",	"g_3H",	"g_4",	"g_5L",	"g_5H",	"g_6L",	"g_6H",	"g_7L",	"g_7H",	"g_8L",	"g_8H",	"g_9L",	"g_9H", "", "d_op",	"d_1L",	"d_1H",	"d_2L",	"d_2H",	"d_3L",	"d_3H",	"d_4",	"d_5L",	"d_5H",	"d_6L",	"d_6H",	"d_7L",	"d_7H",	"d_8L",	"d_8H",	"d_9L",	"d_9H", "", "a_op",	"a_1L",	"a_1H",	"a_2L",	"a_2H",	"a_3L",	"a_3H",	"a_4",	"a_5L",	"a_5H",	"a_6L",	"a_6H",	"a_7L",	"a_7H",	"a_8L",	"a_8H",	"a_9L",	"a_9H", "", "e_op",	"e_1L",	"e_1H",	"e_2L",	"e_2H",	"e_3L",	"e_3H",	"e_4",	"e_5L",	"e_5H",	"e_6L",	"e_6H",	"e_7L",	"e_7H",	"e_8L",	"e_8H",	"e_9L",	"e_9H"]

//compares the spot on fingerboard with an array in markerlibrary.js.js to return a number 0 - 90 then  modulo 12 to get the offset for the scale pattern.  
  let spotNum = spotOptions.indexOf(spot)
  let audIndex = spotNum % 18
  let noteUrl = spotNum % 12
  let currScale;

  //get the notes for a key sig 
  function getSlice (keyRoot, scl) {
    switch (keyRoot){
      case 0:
      return scl.slice(12,24)
      break;
      case 1:
      return scl.slice(11,23)
      break;
      case 2:
        return scl.slice(10,22)
      break;
      case 3:
        return scl.slice(9,21)
      break;
      case 4:
        return scl.slice(8,20)
      break;
      case 5:
        return scl.slice(7,19)
      break;
      case 6:
        return scl.slice(6,18)
      break;
      case 7:
        return scl.slice(5,17)
      break;
      case 8:
        return scl.slice(4,16)
      break;
      case 9:
        return scl.slice(3,15)
      break;  
      case 10:
        return scl.slice(2,14)
      break;
      case 11:
        return scl.slice(1,13)
      break;
    }
  }

  //Sets Scale Pattern 
  let newSlice = getSlice(keyRoot, scales[scaleType].pattern)

  //When Marker is pressed - play note
  function clickHandler(e) {
   
    e.stopPropagation()
    
    const audio = new Audio(
      (inst == 3) ?  VCspotIndex[spotNum].audKey : spotIndex[spotNum].audKey)
     
      if (audio.paused) {
        // Set the playback time to the beginning
        audio.currentTime = 0;
      }

    audio.play()

    setTimeout(() => {
      audio.pause();
    },  2000 / (soundL+1))
  }
 

  //turn markers on and off based on the scale pattern
    if(!newSlice[noteUrl]){
      return(
        <div 
          className="mrkPlace" 
          id={spot} 
          style={{
            opacity: 0, 
            backgroundImage: `Url(${noteSW[noteUrl].imgKey})`
          }}>
        </div>
      )
    } else if(noteUrl == keyRoot) {
      return(
        <div 
          className="mrkPlace" 
          id={spot} style={{
            opacity: 1, 
            backgroundImage: `Url(${noteSG[noteUrl].imgKey})`
          }}
          onClick={clickHandler}
          ></div>
      ) 
    } else if(spot.includes('_op')) {
        return(
          <div 
            className="mrkPlace" 
            id={spot} style={{
              opacity: 1, 
              backgroundImage: `Url(${noteSK[noteUrl].imgKey})`
            }}
            onClick={clickHandler}
            ></div>
        ) 
      }  else {
          return(
            <div className="mrkPlace" 
              id={spot} 
              style={{
                opacity: 1, 
                backgroundImage: isSharp? `Url(${noteSW[noteUrl].imgKey})`:`Url(${noteFW[noteUrl].imgKey})` 
              }}
              onClick={clickHandler}
            ></div>
          )
      }
    }
  

function AboutContent () {
    return(
      <div className="instOpt">
      <p><b>Strangs App™</b> is a scales & chords reference for fiddlers.</p>
      <p>On the <b>Fingerboard</b>, you can select from 15 scales (and modes) in all 12 key signatures. Each <b>Note Marker</b> plays its pitch when pressed, and there is a <b>Position Helper</b> tool to view ghost frets anywhere on the fingerboard.</p>
      <p>The <b>Chord Reference</b> shows the chords by number for each key signature. From the Chord Reference, view the chord's scale or triad on the fingerboard  listen to the root and triad. </p>
      <p>For more information, contact <a href="mailto:10172021@protonmail.com">10172021@protonmail.com</a>.</p>
      </div>
    )
  }

function LearnContent () {
    return(
      <>
      <div className="instOpt">
      
        <h1>Fingerboard </h1><img className="icon-tut" src="../images/fingerboard-icon.png" />
        <p>Pressing the note markers will play there sound.</p> 

        <h1>Key Selector </h1><img className="icon-tut2" src="../images/keySelector.png" />
        <p>The current key is displayed on the left with it's relative key to the right. Press the current key to choose another key from the Circle of 5ths. Press the relative key (in gray) to switch from Major to Minor and back again.</p>

        <h1>Scale Pattern Selection</h1><img className="icon-tut2" src="../images/scale-icon.png" />
        <p>Under the Key Buttons is a drop down menu to choose which scale pattern you'd like to display on the fingerboard.</p>
        </div>

        <div className="instOpt">
        <h1>Swap Sharps and Flats </h1><img className="icon-tut" src={uiBtn[0].imgKey} />
        <p>The Sharp/Flat button allows you to change all the flats and sharps on the fingerboard and on the Circle of 5ths menu to your prefence.</p>

        <h1>Position Helper </h1><img className="icon-tut" src={uiBtn[2].imgKey} /><img className="icon-tut" src={uiBtn[3].imgKey} /><img className="icon-tut" src={uiBtn[4].imgKey} />
        <p>The position helper overlays ghost frets that you can move to any place on the fingerboard.</p><p> There are four available hand patterns. Turn on the Position Helper by pressing the 0-fist button.</p><p> You can change the pattern of the ghost frets and turn off the Position Helper by pressing on the hand button repeatedly. Then click to the left or right of the place on the fingerboard where you want the first finger to be. </p>
      </div>
      <div className="instOpt">
      <h1>Chord Reference </h1>
      <img className="icon-tut" src={uiBtn[15].imgKey} />
      <p>The chord reference menu shows the chords for each key, including the three notes that make up the chord. Also, you can hear the chord or the root of the chord by pressing the audio buttons. </p><p>To see the corresponding key on the fingerboard for the chord press the Chord letter. To see the corresponding triad (arpeggio) on the fingerboard, press the three notes. </p>

      </div>

      </>
    )
  }

  //Menus
function Settings ({opt, contsingle, contmulti}) {
    
    const {menu2} = useInstStore()
    const [isAcc, setIsAcc] = useState(false)

        return(
            <div className={`settMenu ${menu2 ? "active" : ""}`}>  
            <SettInst 
                sID={2} 
                opt="Change Instrument" 
                contsingle={null} 
                contmulti={null} 
                isAcc={isAcc} 
                setIsAcc={setIsAcc} />           
                <SettItem 
                sID={1} 
                opt="About Strangs App™" 
                contsingle={null} 
                contmulti={AboutContent()} 
                isAcc={isAcc} 
                setIsAcc={setIsAcc} />
                 <SettItem 
                sID={3} 
                opt="App Tutorial" 
                contsingle={null} 
                contmulti={LearnContent()} 
                isAcc={isAcc} 
                setIsAcc={setIsAcc} />
            </div>
        )
}

function SettItem ({sID, opt, contsingle, contmulti, isAcc, setIsAcc}) {        
  return(
    <>
      <div className="settItem" onClick={() => {
      (isAcc == sID) ? setIsAcc(0) : setIsAcc(sID)
      }}>
        <p>{opt}</p>
      </div>
      <div className={`accPanel ${isAcc === sID ? "active text" : ""}`} >
       {contmulti}
      </div>
    </>
  )
}

function NextBtn ({side, link}){
    return(
      <a href={link} onClick={(e) => e.stopPropagation()}>
      <img className={`next ${side}`} src={uiBtn[14].imgKey} />
    </a>
    )
}

function SettInst ({sID, opt, contsingle, contmulti, isAcc, setIsAcc}) {
  
  const {inst, setInst, setMenu2} = useInstStore();

  return(
    <>
      <div className="settItem" onClick={() => {
      (isAcc == sID) ? setIsAcc(0) : setIsAcc(sID)
      }}>
        <p>{opt}</p>
      </div>
      <div className={`accPanel ${isAcc === sID ? "active" : ""}`} >
       <div 
          id="inst1_btn" 
          className="instOpt" 
          onClick={() => {
            setInst(0)
            setMenu2(false)}} >
          <h3>{`4 Strang Fiddle${inst == 0 ? " - Current" : ""}`}</h3>
        {/* <NextBtn side="right" link="#inst2_btn" /> */}
        <img src={uiBtn[9].imgKey}  />
       </div>
       <div 
        id="inst2_btn" 
        className="instOpt" 
        onClick={() => {
          setInst(1)
          setMenu2(false)}} >
        <h3>{`5 Strang Electric${inst == 1 ? " - Current" : ""}`}</h3>
        {/* <NextBtn side="left" link="#inst1_btn" />
        <NextBtn side="right" link="#inst3_btn" /> */}
        <img src={uiBtn[10].imgKey}  />
       </div>
       <div 
          id="inst3_btn" 
          className="instOpt" 
          onClick={() =>{
            setInst(2)
            setMenu2(false)}} >
        <h3>{`Viola Fiddle${inst == 2 ? " - Current" : ""}`}</h3>
        {/* <NextBtn side="left" link="#inst2_btn" />
        <NextBtn side="right" link="#inst4_btn" /> */}
        <img src={uiBtn[11].imgKey}  />
       </div>
       <div 
          id="inst4_btn" 
          className="instOpt" 
          onClick={() => {
            setInst(3)
            setMenu2(false)}} >
          <h3>{`Cello${inst == 3 ? " - Current" : ""}`}</h3>
          {/* <NextBtn side="left" link="#inst3_btn" /> */}
          <img src={uiBtn[12].imgKey} />
       </div>
      </div>
    </>
  )
}
    
function Circles() {

    const {isMajor, isSharp, menu1} = useInstStore()

    if (isMajor) {
        return(
          <div className={`keyMenu ${menu1 ? "active" : ""}`}>            
              <ul>
                  <li>
                      <KeyMarker position="crc3" i="3" keyID={0} srcImg= {isSharp ? noteSW[0].imgKey : noteFW[0].imgKey} />
                  </li> <li>
                      <KeyMarker position="crc4" i="4" keyID={7} srcImg= {isSharp ? noteSW[7].imgKey : noteFW[7].imgKey} />
                  </li> <li>
                      <KeyMarker position="crc5" i="5" keyID={2} srcImg= {isSharp ? noteSW[2].imgKey : noteFW[2].imgKey} />
                  </li> <li>
                      <KeyMarker position="crc6" i="6" keyID={9} srcImg= {isSharp ? noteSW[9].imgKey : noteFW[9].imgKey} />
                  </li> <li>
                      <KeyMarker position="crc7" i="7" keyID={4} srcImg= {isSharp ? noteSW[4].imgKey : noteFW[4].imgKey} />
                  </li> <li>
                      <KeyMarker position="crc8" i="8" keyID={11} srcImg= {isSharp ? noteSW[11].imgKey : noteFW[11].imgKey} />
                  </li> <li>
                      <KeyMarker position="crc9" i="9" keyID={6} srcImg= {isSharp ? noteSW[6].imgKey : noteFW[6].imgKey} />
                  </li> <li>
                       <KeyMarker position="crc10" i="10" keyID={1} srcImg= {isSharp ? noteSW[1].imgKey : noteFW[1].imgKey} />
                  </li> <li>
                      <KeyMarker position="crc11" i="11" keyID={8} srcImg= {isSharp ? noteSW[8].imgKey : noteFW[8].imgKey} />
                  </li> <li>
                       <KeyMarker position="crc0" i="0" keyID={3} srcImg= {isSharp ? noteSW[3].imgKey : noteFW[3].imgKey} />
                  </li> <li>
                      <KeyMarker position="crc1" i="1" keyID={10} srcImg= {isSharp ? noteSW[10].imgKey : noteFW[10].imgKey} />
                  </li> <li>
                      <KeyMarker position="crc2" i="2" keyID={5} srcImg= {isSharp ? noteSW[5].imgKey : noteFW[5].imgKey} />
                  </li>
              </ul>
          </div>
      )  
    } else {
      return(
        <div className={`keyMenu ${menu1 ? "active" : ""}`}>            
            <ul>
                <li>
                    <KeyMarker position="crc3" i="3" keyID={9} srcImg= {isSharp ? noteSK[9].imgKey: noteFK[9].imgKey} />
                </li> <li>
                    <KeyMarker position="crc4" i="4" keyID={4} srcImg= {isSharp ? noteSK[4].imgKey: noteFK[4].imgKey} />
                </li> <li>
                    <KeyMarker position="crc5" i="5" keyID={11} srcImg= {isSharp ? noteSK[11].imgKey: noteFK[11].imgKey} />
                </li> <li>
                    <KeyMarker position="crc6" i="6" keyID={6} srcImg= {isSharp ? noteSK[6].imgKey: noteFK[6].imgKey} />
                </li> <li>
                    <KeyMarker position="crc7" i="7" keyID={1} srcImg= {isSharp ? noteSK[1].imgKey: noteFK[1].imgKey} />
                </li> <li>
                    <KeyMarker position="crc8" i="8" keyID={8} srcImg= {isSharp ? noteSK[8].imgKey: noteFK[8].imgKey} />
                </li> <li>
                    <KeyMarker position="crc9" i="9" keyID={3} srcImg= {isSharp ? noteSK[3].imgKey: noteFK[3].imgKey} />
                </li> <li>
                     <KeyMarker position="crc10" i="10" keyID={10} srcImg= {isSharp ? noteSK[10].imgKey: noteFK[10].imgKey} />
                </li> <li>
                    <KeyMarker position="crc11" i="11" keyID={5} srcImg= {isSharp ? noteSK[5].imgKey: noteFK[5].imgKey} />
                </li> <li>
                     <KeyMarker position="crc0" i="0" keyID={0} srcImg= {isSharp ? noteSK[0].imgKey: noteFK[0].imgKey} />
                </li> <li>
                    <KeyMarker position="crc1" i="1" keyID={7} srcImg= {isSharp ? noteSK[7].imgKey: noteFK[7].imgKey} />
                </li> <li>
                    <KeyMarker position="crc2" i="2" keyID={2} srcImg= {isSharp ? noteSK[2].imgKey: noteFK[2].imgKey} />
                </li>
            </ul>
        </div>
     ) 
    }   
  } 

function KeyMarker({position, i, keyID, srcImg}) {
    
    const {setKeyRoot, setMenu1} = useInstStore()

    return (
        <div id={position} style={{'--i': i }} onClick={()=> {
          setKeyRoot(keyID)
          setMenu1(false)
        }}><img src={srcImg} /></div>
    )
}

function ChordRef ({}) {
 
  const {isMajor, menu3} = useInstStore()

      return(
          <div className={`chordMenu ${menu3 ? "active" : ""}`}>     
            <div className="chord-row"> 
              <ChordItem id="iv-chord" i={3} maj={isMajor ? 1 : 0} dim={0} /> 
              <ChordItem id="i-chord" i={0} maj={isMajor ? 1 : 0} dim={0} />       
              <ChordItem id="v-chord" i={4} maj={isMajor ? 1 : 0} dim={0} />
            </div>  
            <div className="chord-row"> 
              <ChordItem id="ii-chord" i={1} maj={0} dim={isMajor? 0 : 1} />  
              <ChordItem id="iii-chord" i={2} maj={isMajor ? 0 : 1} dim={0} />       
              <ChordItem id="vi-chord" i={5} maj={isMajor ? 0 : 1} dim={0} />
              <ChordItem id="vii-chord" i={6} maj={isMajor ? 0 : 1} dim={isMajor? 1 : 0} />      
            </div>
          </div>
      )
      
}

function ChordItem ({i, id, maj, dim}) {
  
  const {
    inst,
    keyRoot,
    scaleType,
    isMajor,
    isSharp,
    menu3,
    setKeyRoot,
    setScaleType,
    setIsMajor,
    setMenu3,
    soundL
  } = useInstStore()

  let typeThird
  let mjThird = 4
  let mnThird = 3
  let mjChordNum = ["I", "ii", "iii", "IV", "V", "vi", "vii°"]
  let mnChordNum = ["i", "ii°", "III", "iv", "v", "VI", "VII"]
  let mjOffset = [0, 2, 4, 5, 7, 9, 11]
  let mnOffset = [0, 2, 3, 5, 7, 8, 10]
  let chordName 
  if(isMajor) {chordName = (keyRoot + mjOffset[i]) % 12} else {chordName = (keyRoot + mnOffset[i]) % 12}
  if(maj) {typeThird = mjThird} else {typeThird = mnThird}

  function playHandler1() {
    const audio = new Audio(
      (inst == 3) ?  VCspotIndex[chordName].audKey : spotIndex[chordName].audKey
      )
    if (audio.paused) {
      // Set the playback time to the beginning
      audio.currentTime = 0;
    }

  audio.play()

  setTimeout(() => {
    audio.pause();
  },  2000 / (soundL+1))



  }

  function playHandler2() {

    const root_audio = new Audio((inst == 3) ?  VCspotIndex[chordName].audKey : spotIndex[chordName].audKey)
    const third_audio = new Audio((inst == 3) ?  VCspotIndex[(chordName + typeThird) % 12].audKey : spotIndex[(chordName + typeThird) % 12].audKey)
    const dominate_audio = new Audio((inst == 3) ?  VCspotIndex[(chordName + 7 - dim) % 12].audKey : spotIndex[(chordName + 7 - dim) % 12].audKey)
  

    if (root_audio.paused) {
      // Set the playback time to the beginning
      root_audio.currentTime = 0;
      third_audio.currentTime = 0;
      dominate_audio.currentTime = 0;
    }

    root_audio.play()
    third_audio.play()
    dominate_audio.play()

  setTimeout(() => {
    root_audio.pause()
    third_audio.pause()
    dominate_audio.pause()
  },  2500 / (soundL+1))
    
  }

  function changeKey (maj) {
    if(maj) {setIsMajor(true)} else {setIsMajor(false)}
  }
  
  function scalePattern () {
// if(maj){setScaleType(1)} else {setScaleType(2)}
//keep the pattern, but switch Major or Minor version, so Minor Pentatonic to Major Pentatonic... instead of Major Scale
    switch(scaleType) {
      case 1:
      case 2:
        if(maj){setScaleType(1)} else {setScaleType(2)}
      break;
      case 3:
        if(maj){setScaleType(1)} else {setScaleType(3)}
      break;
      case 4:
        if(maj){setScaleType(1)} else {setScaleType(4)}
      break;
      case 5:
      case 6:
        if(maj){setScaleType(5)} else {setScaleType(6)}
      break;
      case 7:
      case 8:
        if(maj){setScaleType(7)} else {setScaleType(8)}
      break;
    }
  }

  function triadPattern() {
    if(maj){setScaleType(7)} else {setScaleType(8)}
  }

  function clickHandler(e) {
    e.stopPropagation();
  }

  return(
    <div className="chord-item" id={id}>
      <p className="chord-number"onClick={() => {
        setKeyRoot(chordName)
        changeKey(maj)
        scalePattern()
        setMenu3(false)}} >
        {isMajor? mjChordNum[i] : mnChordNum[i]}
        </p>
      <p className="chord-name" onClick={() => {
        setKeyRoot(chordName)
        changeKey(maj)
        scalePattern()
        setMenu3(false)}} >
        {isSharp ? sNoteNames[chordName]: fNoteNames[chordName]}
        </p>
      
      <p className="chord-triad"onClick={() => {
        setKeyRoot(chordName)
        changeKey(maj)
        triadPattern()
        setMenu3(false)}} >
        {isSharp ? sNoteNames[chordName] + " " : fNoteNames[chordName] + " "}
        {isSharp ? sNoteNames[(chordName + typeThird) % 12] + " ": fNoteNames[(chordName + typeThird) % 12]+ " " }
        {isSharp ? sNoteNames[(chordName + 7 - dim) % 12] : fNoteNames[(chordName + 7 - dim) % 12 ] }
      </p>
      <p  onClick={playHandler1}>root ▶ </p> 
      <p  onClick={playHandler2}>triad	▶</p>
    </div>
  )
 }


//   rendering 
export default App;
